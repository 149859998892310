import { ResetPWAComponent } from './reset-pwa/reset-pwa.component';
import { AuthServiceService } from 'src/app/core/services/auth-service.service';

import { LowerCaseUrlSerializer } from './LowerCaseUrlSerializer';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injector } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GoogleLoginProvider, AuthServiceConfig, SocialLoginModule, FacebookLoginProvider } from 'angularx-social-login';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';

import { environment } from './../environments/environment';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

import { LogoutComponent } from './logout';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { RegisterComponent } from './register/register.component';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthTokenLoadedResolver } from './core/helpers/auth-token-loaded-resolver';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleClientID)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('273698243752080')
  },
  // {
  //   id: LinkedInLoginProvider.PROVIDER_ID,
  //   provider: new LinkedInLoginProvider("78iqy5cu2e1fgr")
  // }
]);

export function provideConfig() {
  return config;
}


export function jwtOptionsFactory(inject: Injector) {
  let auth: AuthServiceService;

  setTimeout(() => {
    auth = inject.get(AuthServiceService);
  });

  return {
    tokenGetter: () => {
      return auth?.getToken();
    },
    whitelistedDomains: [
      environment.host
    ]
  };
}

Sentry.init({
  dsn: 'https://d6009a0e4f21411bab84ddc0f772572b@o451246.ingest.sentry.io/5436866',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'https://test.ezakazi.rs', 'https://www.ezakazi.rs'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    // RegisterComponent,
    ResetPWAComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    SocialLoginModule,
    ClickOutsideModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    RouterModule.forRoot([
      {
        path: '', component: HomeComponent,
        resolve: {
          r: AuthTokenLoadedResolver
        }
      },
      { path: 'logout', component: LogoutComponent },
      { path: 'reset', component: ResetPWAComponent },
      {
        path: 'backoffice', loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackOfficeModule)
      },
      {
        path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      },
      { path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpModule) },
      // { path: '**', redirectTo: '/dada' },
    ], { onSameUrlNavigation: 'reload', useHash: false, enableTracing: false, paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Injector]
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    CoreModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  exports: [
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'sr-Latn' },
    { provide: AuthServiceConfig, useFactory: provideConfig },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    AuthTokenLoadedResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(jwt: JwtHelperService) {
    // circual depencency problem
    // kreiramo JWT service pre prvog requesta kako bi se isti uspešno povezao na dependecy service AuthServiceSerevice
  }
}
